import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {useEffect, useState} from "react";
import {UseAppointmentContext} from "../../Contexts/AppointmentContext";
import upperCase from "../../Helpers/UpperCase";

function PersonalInformations(){

    const {userName,
        setUserName,

        userSurName,
        setUserSurName,

        userPhoneNumber,
        setUserPhoneNumber,

        userEmail,
        setUserEmail,

        personalInformationError,
        setPersonalInformationError} = UseAppointmentContext()

    useEffect(()=>{
        if( userPhoneNumber == ''){
            setUserPhoneNumber('05')
        }
        if(userPhoneNumber){
            if(userPhoneNumber.length > 11){
                setUserPhoneNumber(userPhoneNumber.slice(0, -1))
            }
        }

    },[userPhoneNumber])

    return(
        <>
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Kişi Bilgileri
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={ userName=='' ? personalInformationError:false}
                            value={userName}
                            onChange={event =>setUserName(upperCase(event.target.value))}
                            id="firstName"
                            name="firstName"
                            label="İsim"
                            fullWidth
                            autoComplete="name"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={userSurName =='' ? personalInformationError:false}
                            value={userSurName}
                            onChange={event =>setUserSurName(upperCase(event.target.value))}
                            id="lastName"
                            name="lastName"
                            label="Soyisim"
                            fullWidth
                            autoComplete="surname"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h6" gutterBottom>
                            İletişim Bilgileri
                        </Typography>
                    </Grid>

                    <Grid item className={"flex items-center "} xs={12} md={6}>
                        <LocalPostOfficeIcon fontSize={"large"} color="primary" className={"mr-4"}></LocalPostOfficeIcon>
                        <TextField
                            required
                            error={ userEmail=='' ? personalInformationError:false}
                            value={userEmail}
                            onChange={event =>setUserEmail(event.target.value)}
                            id="eposta"
                            name="eposta"
                            label="E-Posta"
                            fullWidth
                            autoComplete="e-posta"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item className={"flex items-center "} xs={12} md={6}>
                        <LocalPhoneIcon fontSize={"large"} color="primary" className={"mr-4"}></LocalPhoneIcon>
                        <TextField
                            value={userPhoneNumber}
                            error={ (userPhoneNumber=='')|| (userPhoneNumber=='05') ||(userPhoneNumber.length<11) ? personalInformationError:false}
                            required
                            id="phone"
                            name="phone"
                            label="Telefon"
                            fullWidth
                            autoComplete="phone"
                            variant="outlined"
                            onClick={e=> setUserPhoneNumber(e.target.value.replaceAll(" ",""))}
                            onChange={e=> setUserPhoneNumber(e.target.value.replaceAll(" ",""))}
                        />
                    </Grid>

                </Grid>
            </React.Fragment>
        </>
    )
}
export default PersonalInformations