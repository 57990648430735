import { Grid, TextField, Button } from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"
import { Dayjs } from "dayjs"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { addDoc,collection,doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../Firebase";
import { useEffect, useState } from "react";
import Alert from '@mui/material/Alert';

function PsychologistItem({psychologist}){
    const [startTime, setStartTime] = useState(0)
    const [finishTime, setFinishTime] = useState(0)
    const [leaveText, setLeaveText] = useState('')
    const [infoChanged, setInfoChanged] = useState(false)
    const [name,setName] = useState(psychologist.name)
    const [surname,setSurname] = useState(psychologist.surname)

    useEffect(()=>{
        if(psychologist.name!=name||psychologist.surname!=surname){
            setInfoChanged(true)
        }
    },[name,surname])
    return(
        <>
        <Grid item md={2}>
            <TextField
              fullWidth
              label="Ad"
              value={name}
              variant="outlined"
              onChange={e=>setName(e.target.value)}
            />
        </Grid>
        <Grid item md={2}>
            <TextField
              fullWidth
              label="Soyad"
              value={surname}
              variant="outlined"    
              onChange={e=>setSurname(e.target.value)}  
            />
        </Grid>
        
        {
            infoChanged?(
            <Grid item md={12}>
                <Alert severity="warning">Değişiklikleri kaydetmek istiyormusunuz?<tab>          </tab>

                    <Button variant="outlined" color="inherit" onClick={()=>{
                        updateDoc(doc(db, 'doctors', psychologist.id), {
                            name: name,
                            surname:surname
                        })
                        setInfoChanged(false)
                        psychologist.name = name
                        psychologist.surname = surname
                    }}>
                    Kaydet
                    </Button>
            
                </Alert>
                
            </Grid>):(<></>)
        }

        <Grid item md={4}>
            <TextField
              fullWidth
              label="İzin Açıklaması"
              value={leaveText}
              onChange={e=>setLeaveText(e.target.value)}
            />
        </Grid>
        
        <Grid item md={3}>
            <LocalizationProvider
             dateAdapter={AdapterDayjs}
             localeText="tr" 
             adapterLocale={"tr"}
             >
                <DateTimePicker 
                label="İzin Başlangıcı" 
                disabled={leaveText==""?true:false}
                onChange={(newValue)=>setStartTime(newValue.unix())}
                />
            </LocalizationProvider>
        </Grid>
        <Grid item md={3}>
            <LocalizationProvider
             dateAdapter={AdapterDayjs}
             localeText="tr" 
             adapterLocale={"tr"}
             >
                <DateTimePicker 
                label="İzin Bitişi" 
                disabled={startTime==0?true:false}
                onChange={(newValue)=>setFinishTime(newValue.unix())}
                />
            </LocalizationProvider>
        </Grid>
        <Grid item md={2}>
            <div className="mt-2">
                <Button variant="outlined" color="primary" onClick={()=>{
                    if(startTime<finishTime&&leaveText!=''){
                        addDoc(collection(db,'workLeaveList'),{
                            doctorID:psychologist.id,
                            start:startTime,
                            finish:finishTime,
                            text:leaveText
                        })
                        setStartTime(dayjs().startOf("day"))
                        setFinishTime(dayjs().startOf("day"))
                        alert("İzin Oluşturuldu!")
                    }
                    if(leaveText==""){
                        alert("İzin açıklması boş bırakılamaz!")
                    }
                    if(startTime==0||finishTime==0){
                        alert("İzin tarihlerini kontrol ediniz!")
                    }
                }} >
                İzin Oluştur
                </Button>
            </div>
        </Grid>
            <Grid item md={12}>
                <hr></hr>
                <br/>
            </Grid>

        {
            (startTime>finishTime)&&finishTime!=0?(
            <Grid item md={12}>
                <Alert severity="error">İzin başlangıcı bitişinden daha sonra olamaz!</Alert>
            </Grid>):(<></>)
        }


        </>
    )
}
export default PsychologistItem