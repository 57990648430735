import Button from "@mui/material/Button";
import * as React from "react";
import {UseAppointmentContext} from "../../../Contexts/AppointmentContext";
import {useEffect} from "react";

function BookButton({text,disable ,id}){

    const {
        selectedSchedule,
        setSelectedSchedule,
        setSelectedScheduleText
    } = UseAppointmentContext()

    if(selectedSchedule == id){
        setSelectedScheduleText(text)
        return(
            <Button color="success" variant="contained"  onClick={() => {
                setSelectedSchedule(id)
            }} disabled={disable} >{text} </Button>
        )
    }
    else{
        return(
            <Button variant="contained"  onClick={() => {
                setSelectedSchedule(id)
            }} disabled={disable} >{text} </Button>
        )
    }

}
export default BookButton