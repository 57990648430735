import Button from "@mui/material/Button";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import {useEffect, useState} from "react";
import {db} from "../../Firebase";
import {query,collection, onSnapshot,updateDoc, doc, addDoc,deleteDoc} from 'firebase/firestore'

function DeleteButtonWithDialog({id}){
    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <>
            <Button variant="outlined" startIcon={<DeleteOutline />} onClick={handleClickOpen}>
                Randevuyu Sil
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Randevuyu Silmeyi Onaylıyormusunuz?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Silme işlemi sonrası randevu geri getirilemez ve tamamen kaybolur.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Kapat</Button>
                    <Button onClick={()=>{
                        handleClose()
                        const deleteAppointments = async (todo) =>{
                            await deleteDoc(doc(db,'appointments',id))
                        }
                        deleteAppointments()

                    }
                    } autoFocus>
                        Kaydı Sil
                    </Button>
                </DialogActions>
            </Dialog>
        </>
        )
}
export default DeleteButtonWithDialog