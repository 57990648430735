import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {Route, Routes} from "react-router";
import AppointmentLayout from "./Pages/Appoinment/AppointmentLayout";
import Appointment from "./Pages/Appoinment/Appointment";
import DashboardLayoult from "./Pages/Dashboard/DashboardLayoult";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Login from "./Pages/Login/Login";
import PrivateRoute from "./Helpers/PrivateRoute";
import Page404 from "./Pages/404/404";
import {AuthProvider} from "./Contexts/AuthContext";
import PatientManagement from "./Pages/Dashboard/PatientManagement/PatientManagement";
import Patients from "./Pages/Dashboard/PatientManagement/Patients";
import UpcomingAppointments from "./Pages/Dashboard/AppointmentManagement/UpcomingAppointments";
import PastAppointments from "./Pages/Dashboard/AppointmentManagement/PastAppointments";
import PsychologistScreen from "./Pages/Dashboard/PsychologistManagement/PsychologistScreen";
import PsychologistManagement from "./Pages/Dashboard/PsychologistManagement/PsychologistManagement";



function App() {
  return (
      <>
          <AuthProvider>
          <Routes>
              <Route path="/" element={<AppointmentLayout/>}>
                  <Route index={true} element={<Appointment/>}/>
              </Route>

              <Route path="/dashboard/" element={<PrivateRoute><DashboardLayoult/></PrivateRoute>}>
                  <Route index={true} element={<Dashboard/>}/>
                  <Route path="patients" element={<Patients/>} />
                  <Route path="patient/:id" element={<PatientManagement/>} />
                  <Route path="appointments/upcoming" element={<UpcomingAppointments/>}/>
                  <Route path="appointments/past" element={<PastAppointments/>}/>
                  <Route path="psychologist/screen" element={<PsychologistScreen/>}/>
                  <Route path="psychologist/manage" element={<PsychologistManagement/>}/>
              </Route>
              <Route path="/login" element={<Login/>}>
              </Route>
              <Route path="*" element={<Page404/>}></Route>
          </Routes>
          </AuthProvider>


      </>
  );
}

export default App;
