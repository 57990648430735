import {createContext, useContext, useEffect, useState} from "react";

const AuthContext = createContext()

export const AuthProvider = ({children}) => {

    const [isUserLogin, setUserLogin] = useState(JSON.parse(localStorage.getItem('isUserLogin'))||false)

    useEffect(()=>{
        localStorage.setItem('isUserLogin',JSON.stringify(isUserLogin))
    },[isUserLogin])

    const data = {
        isUserLogin:isUserLogin,
        setUserLogin:setUserLogin
    }
    return(
        <AuthContext.Provider value={data}>
            {children}
        </AuthContext.Provider>
    )
}
export const UseAuthContext = () => useContext(AuthContext)