import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../Firebase";

async function getDoctorByID(doctorID,setFunction) {
    const q = query(
        collection(db, 'doctors')
    )
    const docs = await getDocs(q)

    docs.docs.map(async function (doc) {
        if (doc.id == doctorID){
            setFunction({...doc.data(),id:doc.id})
        }
    })
}
export default getDoctorByID