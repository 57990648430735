import dayjs from "dayjs";
import {useEffect, useState} from "react";
import {collection, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {db} from "../../../Firebase";
import getAppointmentsUpcoming from "../../../Helpers/GetAppointmentsUpcoming";
import * as React from "react";
import Title from "../Title";
import AppointmentCompanent from "../AppointmentCompanent";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import getAppointmentsUpcomingWithFilter from "../../../Helpers/GetAppointmentsUpcomingWithFilter";
import getPsychologists from "../../../Helpers/GetPsychologists";
import getAppointmentsUpcomingWithPsychologistFilter
    from "../../../Helpers/GetAppointmentsUpcomingWithPsychologistFilter";

function UpcomingAppointments(){

    const [psychologist, setPsychologist] = React.useState("Hjck4uVgU65JP0Kf1pDJ");

    const handleChangePsycholog = (event) => {
        setPsychologist(event.target.value);
    };

    const [psychologists, setPsychologists] = useState([])
    useEffect(()=>{
        getPsychologists(setPsychologists)
    },[])


    const [appointments, setAppointments] = useState([])

    const[timeFilter,setTimeFilter] = useState(10)
    const handleChange = (event: SelectChangeEvent) => {
        setTimeFilter(event.target.value);
    };

    useEffect(()=>{

        var startTimeLimit = 0
        var endTimeLimit = 0
        if(timeFilter == 10){
            startTimeLimit = dayjs().startOf("day").unix()
            endTimeLimit = dayjs().endOf("day").unix()
        }
        else if(timeFilter == 20){
            startTimeLimit = dayjs().unix()
            endTimeLimit = dayjs().endOf("week").unix()
        }
        else if(timeFilter == 30){
            startTimeLimit = dayjs().unix()
            endTimeLimit = dayjs().endOf("month").unix()
        }
        else {
            startTimeLimit = dayjs().unix()
            endTimeLimit = dayjs().endOf("year").unix()
        }
        //getAppointmentsUpcoming(setAppointments)
        getAppointmentsUpcomingWithPsychologistFilter(startTimeLimit,endTimeLimit,setAppointments,psychologist)
    },[timeFilter,psychologist])


    return (
        <React.Fragment>
            <div className="p-4">
                <Paper>
                    <div className="p-4 mb-4">
                        <Title>Gelecek Randevular</Title>
                        <Grid container spacing={4} rowSpacing={2}>
                            <Grid item md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Zaman</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={timeFilter}
                                        label="Age"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>Bu Gün</MenuItem>
                                        <MenuItem value={20}>Bu Hafta</MenuItem>
                                        <MenuItem value={30}>Bu Ay</MenuItem>
                                        <MenuItem value={40}>Tüm Randevular</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Psikolog</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={psychologist}
                                        label="Psikolog"
                                        onChange={handleChangePsycholog}
                                    >
                                        {
                                            psychologists?psychologists.map((item)=>(
                                                <MenuItem value={item.id}>{item.name} {item.surname}</MenuItem>
                                            )):(<MenuItem value={0}>Doktor Bulunamadı</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {
                            appointments == ""?(<div className="p-6">Randevu Bulunmamaktadır!</div>):appointments.map((app)=>(
                                <div className="mt-6 p-4 hover:bg-gray-100">
                                    <AppointmentCompanent key={app.id} appointment={app}/>
                                </div>
                            ))

                        }
                    </div>
                </Paper>
            </div>


        </React.Fragment>
    )
}
export default UpcomingAppointments