import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PersonalInformations from "./PersonalInformations";
import AppointmentInformations from "./AppointmentInformations";
import Confirmation from "./Confirmation";
import {UseAppointmentContext} from "../../Contexts/AppointmentContext";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import {addDoc, collection, getDocs, query} from "firebase/firestore";
import {db} from "../../Firebase";
import upperCase from "../../Helpers/UpperCase"
import getScheculeByID from "../../Helpers/GetScheculeByID";
import dayjs from "dayjs";
import ConfirmDialog from "./Components/ConfirmDialog";
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://bursaklinikpsikolojimerkezi.com/">
               Bursa Klinik Psikoloji Merkezi
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const steps = ['Kişi ve İletişim Bilgileri', 'Randevu Bilgileri', 'Onay'];

function getStepContent(step) {
    switch (step) {
        case 0:
            return <PersonalInformations />;
        case 1:
            return <AppointmentInformations />;
        case 2:
            return <Confirmation />;
        default:
            throw new Error('Unknown step');
    }
}



// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Appointment() {
    const [activeStep, setActiveStep] = React.useState(0);

    const {
        userName,
        setUserName,

        userSurName,
        setUserSurName,

        userPhoneNumber,
        setUserPhoneNumber,

        userEmail,
        setUserEmail,

        personalInformationError,
        setPersonalInformationError,

        doctor,
        doctorText,
        setDoctor,
        setDoctorText,

        date,
        setDate,

        selectedSchedule,
        setSelectedSchedule,
        selectedScheduleText,
        setSelectedScheduleText,
        scheduleError,
        setScheduleError,

        appointmentCompleted,
        setAppointmentCompleted

        } = UseAppointmentContext()

    const [dialogStatus,setDialogStatus] = useState(false)
    const handleNext = () => {
        if(activeStep==0){
            if(userName=='' || userSurName =='' || userEmail =='' || userPhoneNumber=='' || userPhoneNumber=='05' || userPhoneNumber.length<11 ){
                setPersonalInformationError(true)
                return
            }
            else{
                setPersonalInformationError(false)
            }
        }
        else if(selectedSchedule==null){
            setScheduleError(true)
            return
        }
        if(activeStep=='2'){
            setDialogStatus(true)
            return
        }
        setActiveStep(activeStep + 1);
    };

    const dialogNext = () => {
        setActiveStep(activeStep + 1);
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    async function createSchedule(){
        var is_patient_exist = false
        var patient_id = null

        const patients = query(collection(db,'patients'))
        const patients_docs = (await getDocs(patients))
        const patient_list = []
        patients_docs.docs.map(function (doc){

            if(doc.data().name == userName && doc.data().surname == userSurName && doc.data().phoneNumber == userPhoneNumber){
                is_patient_exist = true
                patient_id = doc.id
            }
        })

        if(!is_patient_exist){
            const patient = await addDoc(collection(db,'patients'),{
                eposta: userEmail,
                name: upperCase(userName),
                surname: upperCase(userSurName),
                phoneNumber: userPhoneNumber
            })
            patient_id = patient.id
        }

        const selectedCheduleTimes = selectedScheduleText.split("-");
        const hours = selectedCheduleTimes[0].split(":");

        date.set('hour',hours[0]).set('minute',hours[1])
        var day = date
        day = day.set('hour',hours[0]).set('minute',hours[1])

        const doc = await addDoc(collection(db,'appointments'),{
            blockTime: true,
            date:day.format(),
            timestamp: day.unix(),
            creationTimestamp: dayjs().unix(),
            doctorID:doctor,
            patientID: patient_id,
            scheduleID: selectedSchedule,
            appointmentStatesID:'u1ew5UUckMNcgWpzO2Qy',
            isPaid:false,
            serviceID:'uGC1eVd6pmScGUcbAIiA'
        })

        var smsText = "Sayın "+ userName +" "+ userSurName+" randevunuzu başarı ile aldık! Randevu Tarihiniz "+ date.format("DD/MM/YYYY") + " Saat "+ selectedScheduleText+ " Bir problem yaşamanız durumunda bizlere 0224 502 53 53 numaralı telefonumuzdan ulaşabilirsiniz!"
        
        fetch('https://apiv3.goldmesaj.net/api/sendSMS',{
            method:'POST', headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            }, body: JSON.stringify({
                "username": "B.Fılız861*",
                "password": "Filiz16Klinik123?",
                "sdate": "",
                "vperiod": "48",
                "message": {
                    "sender": "FiLiZ BSLGN",
                    "text": smsText,
                    "utf8": "1",
                    "gsm": [userPhoneNumber]
                }
            })
        })
            .then(res=> res.json())
            .then(data => console.log(data))
            .catch(err=> console.log(err))
    }

    useEffect(()=>{
        if(appointmentCompleted){
            createSchedule()
            setTimeout(function() {
                window.location.replace('http://bursaklinikpsikolojimerkezi.com/randevu-onay/');
              }, 3000);
        }
    },[appointmentCompleted])

    

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />

            <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography component="h1" variant="h4" align="center">
                        Online Randevu Sistemi
                    </Typography>
                    <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length ? (
                        <React.Fragment>
                            {setAppointmentCompleted(true)}
                            <Typography variant="h5" gutterBottom>
                                Randevunuz için teşekkür ederiz!
                            </Typography>
                            <Typography variant="subtitle1">
                                Randevu bilgileriniz sizlere SMS yoluyla iletilecektir. Bir sorun yaşadığınızda bizlere iletişim sayfamızdaki bilgilerimizden ulaşabilirsiniz!
                            </Typography>

                            <Grid container spacing={4}>

                                <Grid item xs={12} >

                                    <hr/>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {getStepContent(activeStep)}
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {activeStep !== 0 && (
                                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                        Geri
                                    </Button>
                                )}

                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    sx={{ mt: 3, ml: 1 }}
                                >
                                    {activeStep === steps.length - 1 ? 'Randevu Oluştur' : 'İleri'}
                                </Button>
                            </Box>
                        </React.Fragment>
                    )}
                </Paper>
                <Copyright />
                <ConfirmDialog status={dialogStatus} nextPage={dialogNext} setStatus={setDialogStatus}> </ConfirmDialog>
            </Container>
        </ThemeProvider>
    );
}
