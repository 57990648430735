import {createContext, useContext, useEffect, useState} from "react";
import dayjs from "dayjs";

const Context = createContext()


export const AppointmentProvider = ({children}) =>{
    //Personal Information's
    const [userName,setUserName] = useState(JSON.parse(localStorage.getItem('userName'))||'')
    const [userSurName,setUserSurName] = useState(JSON.parse(localStorage.getItem('userSurName'))||'')
    const [userPhoneNumber,setUserPhoneNumber] = useState(JSON.parse(localStorage.getItem('userPhoneNumber'))||'')
    const [userEmail,setUserEmail] = useState(JSON.parse(localStorage.getItem('userEmail'))||'')
    const [personalInformationError,setPersonalInformationError] = useState(false)


    //Appointment Form
    const [doctor, setDoctor] = useState(null)
    const [doctorText, setDoctorText] = useState("")
    const [date,setDate] = useState(null)
    const [selectedSchedule, setSelectedSchedule] = useState(null)
    const [scheduleError, setScheduleError] = useState(false)
    const[selectedScheduleText, setSelectedScheduleText] = useState("")
    const[appointmentCompleted, setAppointmentCompleted] = useState(false)






    const data={
        userName,
        setUserName,

        userSurName,
        setUserSurName,

        userPhoneNumber,
        setUserPhoneNumber,

        userEmail,
        setUserEmail,

        personalInformationError,
        setPersonalInformationError,

        doctor,
        doctorText,
        setDoctor,
        setDoctorText,

        date,
        setDate,

        selectedSchedule,
        setSelectedSchedule,
        selectedScheduleText,
        setSelectedScheduleText,
        scheduleError,
        setScheduleError,

        appointmentCompleted,
        setAppointmentCompleted
    }

    useEffect(()=>{
        localStorage.setItem('userName',JSON.stringify(userName))
    },[userName])

    useEffect(()=>{
        localStorage.setItem('userSurName',JSON.stringify(userSurName))
    },[userSurName])

    useEffect(()=>{
        localStorage.setItem('userPhoneNumber',JSON.stringify(userPhoneNumber))
    },[userPhoneNumber])

    useEffect(()=>{
        localStorage.setItem('userEmail',JSON.stringify(userEmail))
    },[userEmail])



    return(
        <Context.Provider value={data}>
            {children}
        </Context.Provider>
    )
}

export const UseAppointmentContext = () => useContext(Context)
