import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {db} from "../Firebase";
import dayjs from "dayjs";

async function getAppointmentsUpcoming(setFunction) {
    var startSearch = dayjs().startOf("day").unix()
    const q = query(
        collection(db,'appointments'),
        where("timestamp", ">=", startSearch),
        where("timestamp", "<=", dayjs().endOf("day").unix()),
        orderBy("timestamp")
    )
    const docs = await getDocs(q)
    var list = []
    docs.docs.map(async function (doc) {
            list.push({...doc.data(),id:doc.id})
    })
    setFunction(list)

}
export default getAppointmentsUpcoming