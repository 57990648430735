import Title from "../Title";
import * as React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";
import getPsychologists from "../../../Helpers/GetPsychologists";
import Typography from "@mui/material/Typography";
import {collection, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {db} from "../../../Firebase";
import dayjs from "dayjs";
import AppointmentCompanent from "../AppointmentCompanent";

function ExpandMoreIcon() {
    return null;
}

function PsychologistScreen(){

    const [psychologist, setPsychologist] = React.useState("Hjck4uVgU65JP0Kf1pDJ");

    const handleChange = (event) => {
        setPsychologist(event.target.value);
    };

    const [psychologists, setPsychologists] = useState([])
    useEffect(()=>{
        getPsychologists(setPsychologists)
    },[])


    const [planedAppointments, setPlanedAppointments] = useState([])
    const [enteredAppointments, setEnteredAppointments] = useState([])
    const [completedAppointments, setCompletedAppointments] = useState([])
    const [canceledAppointments, setCanceledAppointments] = useState([])

    useEffect(()=>{

        const q = query(
            collection(db, 'appointments'),
            where("timestamp" ,">=" , dayjs().startOf("day").unix()),
            where("timestamp" ,"<=" , dayjs().endOf("day").unix()),
            orderBy("timestamp","desc")
        )

        const unsubscribe = onSnapshot(q,(querySnapshot) => {

            var planedAppointmentsArr = []
            var enteredAppointmentsArr = []
            var completedAppointmentsArr = []
            var canceledAppointmentsArr = []

            querySnapshot.forEach((doc)=>{
                if(psychologist==doc.data().doctorID){
                    if(doc.data().appointmentStatesID=="u1ew5UUckMNcgWpzO2Qy"){
                        planedAppointmentsArr.push({...doc.data(),id:doc.id})
                    }
                    if(doc.data().appointmentStatesID=="R7DXK7a9If8jX1H2KnV6"){
                        enteredAppointmentsArr.push({...doc.data(),id:doc.id})
                    }
                    if(doc.data().appointmentStatesID=="JYMrIgrLS3RlP2gtceYZ"){
                        completedAppointmentsArr.push({...doc.data(),id:doc.id})
                    }
                    if(doc.data().appointmentStatesID=="ZgttzwgvXnQA9SHaakw0"){
                        canceledAppointmentsArr.push({...doc.data(),id:doc.id})
                    }
                    setPlanedAppointments(planedAppointmentsArr)
                    setEnteredAppointments(enteredAppointmentsArr)
                    setCompletedAppointments(completedAppointmentsArr)
                    setCanceledAppointments(canceledAppointmentsArr)
                }
            });
        })
        return () => unsubscribe
    },[psychologist])

    return(
        <React.Fragment>
            <div className="p-2">
                <Paper>
                    <div className="p-4 mb-4">
                        <Title>Psikolog Ekranı</Title>
                        <Grid container spacing={4} rowSpacing={2}>
                            <Grid item md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Psikolog</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={psychologist}
                                        label="Psikolog"
                                        onChange={handleChange}
                                    >
                                        {
                                            psychologists?psychologists.map((item)=>(
                                                <MenuItem value={item.id}>{item.name} {item.surname}</MenuItem>
                                            )):(<MenuItem value={0}>Doktor Bulunamadı</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            </div>

            <div className="p-2">
                <Accordion disabled={planedAppointments.length==0?true:false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Planlanmış Randevular ({planedAppointments.length})</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            planedAppointments.length != 0?planedAppointments.map((item)=>(
                                <AppointmentCompanent key={item.id} appointment={item}/>
                            )):(<>Planlanmış Randevu Bulunmamaktadır!</>)
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion disabled={enteredAppointments.length==0?true:false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Giriş Yapmış Danışanlar ({enteredAppointments.length})</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            enteredAppointments.length != 0?enteredAppointments.map((item)=>(
                                <div className="bg-blue-200">
                                    <AppointmentCompanent key={item.id} appointment={item}/>
                                </div>

                            )):(<>Giriş Yapmış Danışan Bulunmamaktadır!</>)
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion disabled={completedAppointments.length==0?true:false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Görüşmeye Alınmış Randevular ({completedAppointments.length})</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            completedAppointments.length != 0?completedAppointments.map((item)=>(
                                <AppointmentCompanent key={item.id} appointment={item}/>
                            )):(<>Tamamlanmış Randecu Bulunmamaktadır!</>)
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion disabled={canceledAppointments.length==0?true:false} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>İptal Edilmiş Randevular ({canceledAppointments.length}) </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            canceledAppointments.length != 0?canceledAppointments.map((item)=>(
                                <AppointmentCompanent key={item.id} appointment={item}/>
                            )):(<>İptal Edilmiş Randecu Bulunmamaktadır!</>)
                        }
                    </AccordionDetails>
                </Accordion>

            </div>
        </React.Fragment>
    )
}
export default PsychologistScreen