import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../Firebase";

async function getPsychologists(setFunction) {
    const q = query(
        collection(db, 'doctors'),
        where("active", "==", true) // Only get documents where "active" is true
    );

    const docs = await getDocs(q);

    let list = [];
    docs.forEach((doc) => {
        list.push({ ...doc.data(), id: doc.id });
    });

    setFunction(list);
}

export default getPsychologists;