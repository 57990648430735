import Button from "@mui/material/Button";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import {useEffect, useState} from "react";
function ConfirmDialog({status,setStatus,nextPage}){
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        if (status){
            handleClickOpen()
        }
    },[status])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Randevuyu Oluşturmayı Onaylıyormusunuz?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Bu adımdan sonra randevunuz oluşturulacak ve randevu bilgileriniz sizlere sms yoluyla iletilecektir.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{
                        handleClose()
                        setStatus(false)
                    }
                    }>Kapat</Button>
                    <Button onClick={()=>{
                        handleClose()
                        nextPage()
                    }
                    } autoFocus>
                       Randevuyu Oluştur
                    </Button>
                </DialogActions>
            </Dialog>
        </>
        )
}
export default ConfirmDialog