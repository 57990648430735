import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../Firebase";

async function getPatientByID(patientID,setFunction) {
    const q = query(
        collection(db, 'patients')
    )
    const docs = await getDocs(q)

    docs.docs.map(async function (doc) {
        if (doc.id == patientID){
            setFunction({...doc.data(),id:doc.id})
        }
    })
}
export default getPatientByID