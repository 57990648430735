import {collection, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {db} from "../Firebase";

function getAppointmentsByPatientID(patientID,setFunction){

    const q = query(
        collection(db, 'appointments'),
        orderBy("timestamp","desc")
    )

    const unsubscribe = onSnapshot(q,(querySnapshot) => {
        let todosArr = []
        querySnapshot.forEach((doc)=>{
            if(patientID==doc.data().patientID){
                todosArr.push({...doc.data(),id:doc.id})
            }
        });
        setFunction(todosArr)
    })
    return () => unsubscribe

}
export default getAppointmentsByPatientID