import Paper from "@mui/material/Paper";
import Title from "../Title";
import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import getAllPatientWithFilter from "../../../Helpers/GetAllPatientWithFilter";
import upperCase from "../../../Helpers/UpperCase";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

function Patients(){

    const [filter,setFilter] = useState("")

    const [limitPatients, setLimitPatients] = useState(10)

    const [patients,setPatients] = useState([])
    useEffect(()=>{
        getAllPatientWithFilter("",limitPatients,setPatients)
    },[])

    useEffect(()=>{
        var text = upperCase(filter)
        getAllPatientWithFilter(text,limitPatients,setPatients)
    },[filter,limitPatients])

    const handleChange = (event: SelectChangeEvent) => {
        setLimitPatients(event.target.value);
    };


    return(
        // eslint-disablse-next-line react/jsx-no-undef
        <React.Fragment>
            <div className="p-6">
                <Paper>
                    <div className="p-6">
                        <Title>Danışan Listesi</Title>
                        <Grid container spacing={4} rowSpacing={2}>
                            <Grid item md={4}>
                                <TextField fullWidth id="outlined-basic" label="Arama" value={filter} onChange={e=>setFilter(e.target.value)} variant="outlined" />
                            </Grid>
                            <Grid item md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Kaç Adet Danışan Listelensin</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={limitPatients}
                                        label="Kaç Adet Danışan Listelensin"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={0}>Tamamı</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} rowSpacing={2}>
                            <Grid item md={12}>
                                <div className="bg-blue-400 mt-6 p-4 text-white">
                                    <Grid container spacing={4} rowSpacing={2}>
                                        <Grid item md={3}>
                                            Ad
                                        </Grid>
                                        <Grid item md={3}>
                                            Soyad
                                        </Grid>
                                        <Grid item md={3}>
                                            Telefon
                                        </Grid>
                                        <Grid item md={3}>
                                            E-Posta
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>


                            {
                                patients.map((patient)=>(
                                    <Grid key={patient.id} item md={12}>
                                        <a href={"/dashboard/patient/"+patient.id}>
                                            <div className="mt-3 mb-3 p-4 hover:bg-gray-100">
                                                <Grid container spacing={4} rowSpacing={2}>
                                                    <Grid item md={3}>
                                                        {patient.name}
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        {patient.surname}
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        {patient.phoneNumber}
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        {patient.eposta}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </a>
                                        <hr/>
                                    </Grid>
                                ))
                            }
                        </Grid>

                    </div>
                </Paper>
            </div>
        </React.Fragment>
    )
}
export default Patients