import Checkbox from "@mui/material/Checkbox";
import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {useEffect, useState} from "react";
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../Firebase";
import setPriceByAppointmentID from "../../Helpers/SetPriceByAppointmentID";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

function ConfirmPaymentWithDialog({appointment,service,price,setPrice}){
    console.log(appointment.id)

    const [isPaid, setIsPaid] = useState(appointment.isPaid)
    const handleChange1 = () => {
        if (isPaid){
            setIsPaid(false)
        }
        else{
            setIsPaid(true)
        }
        handleClose()
    };

    useEffect(()=>{
        const appointmentPayment = async () =>{
            await updateDoc(doc(db,'appointments', appointment.id),{
                isPaid: isPaid
            })
        }
        appointmentPayment()
    },[isPaid])

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return(
            <Grid container spacing={4} rowSpacing={2}>
                <Grid item md={6}>
                    <TextField disabled={isPaid} onChange={e=>{
                        setPrice(e.target.value)
                        setPriceByAppointmentID(appointment.id,e.target.value)
                    }} fullWidth id="outlined-basic" value={price} label="Ücret" variant="outlined" />
                </Grid>
                <Grid item md={6}>
                    <Checkbox disabled={service!="uGC1eVd6pmScGUcbAIiA"?false:true}  onChange={handleClickOpen} label="Ödeme Alındı" checked={service!="uGC1eVd6pmScGUcbAIiA"?isPaid:false}/> Ödeme Onayı
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Ödemeyi Onaylıyormusnuz?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Disagree</Button>
                            <Button onClick={handleChange1} autoFocus>
                                Onaylıyorum
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
    )
}
export default ConfirmPaymentWithDialog