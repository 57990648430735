import { Grid, TextField, Button } from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"
import { Dayjs } from "dayjs"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { addDoc,collection ,doc, deleteDoc} from "firebase/firestore";
import { db } from "../../../../Firebase";
import { useEffect, useState } from "react";
import Alert from '@mui/material/Alert';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

function WorkLeaveListItem({leave,psychologistList}){
    var psycholog = {}
    psychologistList.map((psy)=>{
        if(leave.doctorID==psy.id){
            psycholog=psy
        }
    })

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <>
        
        <Grid item md={3}>
            <b>Psikolog</b>
        </Grid>

        <Grid item md={3}>
            <b>İzin Açıklaması</b>
        </Grid>

        <Grid item md={2}>
            <b>Başlangıç Tarihi</b>
        </Grid>
        
        <Grid item md={2}>
            <b>Bitiş Tarihi</b>
        </Grid>
        <Grid item md={2}>
            
        </Grid>
        <Grid item md={12}>
            <hr></hr>
        </Grid>


        <Grid item md={3}>
            {psycholog.name} {psycholog.surname}
        </Grid>

        <Grid item md={3}>
            {leave.text}
        </Grid>

        <Grid item md={2}>
            {dayjs.unix(leave.start).format("HH:mm DD/MM/YY")}
        </Grid>
        
        <Grid item md={2}>
            {dayjs.unix(leave.finish).format("HH:mm DD/MM/YY")}
        </Grid>
        <Grid item md={2}>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
              İznİ Sil
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"İzni Silmeyi Onaylıyormusnuz?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>İzni Silme</Button>
                <Button onClick={()=>{
                    handleClose()
                    const deleteLeave = async () =>{
                        await deleteDoc(doc(db,'workLeaveList',leave.id))
                    }
                    deleteLeave()
                }} autoFocus>
                    İzni Sil
                </Button>
                </DialogActions>
            </Dialog>
        </Grid>
        <Grid item md={12}>
            <hr></hr>
        </Grid>
        

        </>
    )
}
export default WorkLeaveListItem