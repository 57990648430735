import Paper from "@mui/material/Paper";
import Title from "../Title";
import * as React from "react";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import getAppointmentsByPatientID from "../../../Helpers/GetAppointmentsByPatientID";
import AppointmentCompanent from "../AppointmentCompanent";

function  PatientAppointments({patient}){

    const [appointments, setAppointments] = useState([])
    useEffect(()=>{
        getAppointmentsByPatientID(patient.id,setAppointments)
    },[])

    if(appointments!=[]){
        return(
            <div className="mt-6">
                <Paper>
                    <div className="p-6">
                        <Title>Hastanın Randevuları</Title>
                        <Grid container spacing={4} rowSpacing={2}>
                            {console.log(appointments)}
                            {
                                appointments.map((appointment)=>(
                                    <div className="ml-8 mt-6 hover:bg-gray-50 rounded">
                                        <AppointmentCompanent key={appointment.id} appointment={appointment}></AppointmentCompanent>
                                    </div>

                                ))
                            }
                        </Grid>
                    </div>
                </Paper>
            </div>
        )
    }


}
export default PatientAppointments