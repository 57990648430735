import {useEffect, useState} from "react";
import getPatientNotesByID from "../../../Helpers/GetPatientNotesByID";
import Paper from "@mui/material/Paper";
import Title from "../Title";
import * as React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {addDoc, collection, deleteDoc, doc, updateDoc} from "firebase/firestore";
import {db} from "../../../Firebase";
import Dialog from "@mui/material/Dialog";
import Note from "./Note";

function PatientNotes({patient}){

    var patientID = patient.id
    const [notes, setNotes] = useState([])
    useEffect(()=>{

        getPatientNotesByID(patientID,setNotes)

    },[])

    const[newNoteText, setNewNoteText] = useState("")
    const saveNote = async () => {
        if(newNoteText!=""){
            await addDoc(collection(db, 'patientsNotes'), {
                text: newNoteText,
                patientID:patientID,
                createdAt: dayjs().unix()
            })
            setNewNoteText("")
        }
    }

    return (
        <Paper>
            <div className="p-6 mt-6">
                <div className="mb-4">
                    <Title>Yeni Not Oluştur</Title>
                    <Grid container spacing={4} rowSpacing={2}>
                        <Grid item md={9}>
                            <TextField
                                fullWidth
                                label="Yeni Not"
                                multiline
                                maxRows={4}
                                variant="outlined"
                                value={newNoteText}
                                onChange={e=>setNewNoteText(e.target.value)}
                            >

                            </TextField>
                        </Grid>
                        <Grid item md={3}>
                            <Button fullWidth variant="outlined"  onClick={saveNote}>
                                Notu Kaydet
                            </Button>
                        </Grid>
                    </Grid>
                </div>

                <Title>Danışana Ait Notlar</Title>
                {
                    notes.length==0?(<>Danışana ait not bulunamadı!</>):(<></>)
                }
                {notes.map((note)=>(
                    <Note key={note.id} note={note} />
                ))}
            </div>
        </Paper>
    )
}
export default PatientNotes