import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import getPatientByID from "../../../Helpers/GetPatientByID";
import Title from "../Title";
import AppointmentCompanent from "../AppointmentCompanent";
import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {Alert, ButtonGroup} from "@mui/material";
import Button from "@mui/material/Button";
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../../Firebase";
import PatientNotes from "./PatientNotes";
import PatientInfo from "./PatientInfo";
import PatientAppointments from "./PatientAppointments";
function PatientManagement(){
    const {id} = useParams()

    const [patient, setPatient] = useState(null)




    useEffect(()=>{
        getPatientByID(id,setPatient)
    },[])



    if(patient!=null){
        return(
            <React.Fragment>

                <PatientInfo patient={patient}/>

                <PatientNotes patient={patient} />

                <PatientAppointments patient={patient}/>


            </React.Fragment>
        )
    }
    return (
        <>
            Hasta Bulunamadı
        </>
    )

}
export default PatientManagement