import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import MonitorIcon from '@mui/icons-material/Monitor';
import {AlarmOn} from "@mui/icons-material";

export const mainListItems = (
  <React.Fragment>
      <ListSubheader component="div" inset>
          Ana Menü
      </ListSubheader>
    <ListItemButton to="/dashboard/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Randevu Oluştur" />
    </ListItemButton>
      <ListItemButton to="/dashboard/appointments/upcoming">
          <ListItemIcon>
              <AlarmOnIcon />
          </ListItemIcon>
          <ListItemText primary="Gelecek Randevular" />
      </ListItemButton>
      <ListItemButton to="/dashboard/appointments/past">
          <ListItemIcon>
              <AlarmOffIcon />
          </ListItemIcon>
          <ListItemText primary="Geçmiş Randevular" />
      </ListItemButton>
    <ListItemButton to="/dashboard/patients">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Hastalar" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Hizmetler" />
    </ListItemButton>
    <ListItemButton to="/dashboard/psychologist/manage">
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Psikologlar" />
    </ListItemButton>
      <ListItemButton to="/dashboard/psychologist/screen">
          <ListItemIcon>
              <MonitorIcon />
          </ListItemIcon>
          <ListItemText primary="Psikolog Ekranı" />
      </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Çıkış Yap" />
    </ListItemButton>
  </React.Fragment>
);
