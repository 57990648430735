import {Outlet} from "react-router";
import logo from "../../images/logo.png";
import {AppointmentProvider,UseAppointmentContext} from "../../Contexts/AppointmentContext";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/tr';

const headerStyle ={
    container:" w-screen p-4",
    img:"m-auto object-scale-down h-12",
    p:"text-3xl font-bold text-center text-gray-700 p-2",
    hr:"w-60 h-1 mx-auto rounded md:my-5 dark:bg-gray-400"
}

function AppointmentLayout(){
    return (
            <AppointmentProvider>
                <div >
                    <div className={headerStyle.container}>
                        <img className={headerStyle.img} src={logo}/>
                        <p className={headerStyle.p}>Bursa Klinik Psikoloji Merkezi</p>
                    </div>
                </div>
                <Outlet>

                </Outlet>
            </AppointmentProvider>
    )
}
export default AppointmentLayout