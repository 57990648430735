import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../Firebase";

async function getSchecule(scheduleID,setFunction) {
    const q_document = query(
        collection(db, 'scheduling'),
    )
    const documents = await getDocs(q_document)
    documents.docs.map(async function (doc) {

        const q_times = query(
            collection(db, 'scheduling/'+doc.id+'/times')
        )
        const times = await getDocs(q_times)
        times.docs.map(async function (time) {
            if(time.id == scheduleID){
                setFunction({...time.data(),id:time.id})
            }

        })
    })
}
export default getSchecule