import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {db} from "../Firebase";
import dayjs from "dayjs";
import getPatientByID from "./GetPatientByID";
import {useEffect, useState} from "react";
import upperCase from "./UpperCase";

async function getAppointmentsUpcomingWithPsychologistFilter(startTime,endTime,setFunction,doctorId) {

    const q = query(
        collection(db,'appointments'),
        where("timestamp", ">=", startTime),
        where("timestamp", "<=", endTime),
        orderBy("timestamp")
    )
    const docs = await getDocs(q)
    var list = []

    docs.docs.map(async function (doc) {
        if(doc.data().doctorID=doctorId){
            list.push({...doc.data(),id:doc.id})
        }
    })
    setFunction(list)
}
export default getAppointmentsUpcomingWithPsychologistFilter