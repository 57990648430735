import {UseAuthContext} from "../Contexts/AuthContext";
import {Navigate} from "react-router";

function PrivateRoute({children}){
    const {isUserLogin} = UseAuthContext()
    if(!isUserLogin){
        return<Navigate to="/login"/>
    }
    return (
        <>
            {children}
        </>
    )
}
export default PrivateRoute