import {collection, getDocs, onSnapshot, orderBy, query,limit} from "firebase/firestore";
import {db} from "../Firebase";

async function getAllPatientWithFilter(text,limitPatients, setFunction) {

    const q = query(
        collection(db, 'patients')
    )
    const docs = await getDocs(q)

    var patientList = []
    docs.docs.map(async function (doc) {
        if(text!="" && (doc.data().name.includes(text)||doc.data().surname.includes(text) )){
            patientList.push({...doc.data(), id: doc.id})
        }
        else if(text==""){
            patientList.push({...doc.data(), id: doc.id})
        }
    })

    if(limitPatients!=0){
        var limitedList = patientList.splice(0,limitPatients)
    }
    else{
        var limitedList = patientList
    }
    setFunction(limitedList)
}
export default getAllPatientWithFilter