import {UseAppointmentContext} from "../../Contexts/AppointmentContext";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import StopIcon from "@mui/icons-material/Stop";
import BookButton from "./Components/BookButton";
import AlertBox from "./Components/AlertBox";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {useEffect, useRef} from "react";
import {ReCAPTCHA} from "react-google-recaptcha";

function Confirmation(){
    const {
        userName,
        userSurName,
        userEmail,
        userPhoneNumber,
        date,
        doctor,
        doctorText,
        setDoctorText,
        selectedSchedule,
        selectedScheduleText,
    } = UseAppointmentContext()


    async function getDoctors(target_id){
        const q = query(collection(db,'doctors'))
        const docs = await getDocs(q)
        const doctors_list = []
        docs.docs.map(function (doc){
            if(doc.id == target_id){
                setDoctorText(doc.data().name+' '+doc.data().surname)
            }
        })
    }
    useEffect(()=>{
        getDoctors(doctor)
    },[])

    return(
        <>
            <React.Fragment>

                <Grid container spacing={4}>
                    <Grid item xs={6} className={"mt-4"}>
                        <b>Kişi Bilgileri</b>
                    </Grid>
                    <Grid item xs={6}>
                        <hr/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <b>Ad - Soyad:</b> {userName} {userSurName}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <b>Telefon Numarası:</b> {userPhoneNumber}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <b>E-Posta:</b> {userEmail}
                    </Grid>
                    <Grid item xs={12}>
                        <hr/>
                    </Grid>

                    <Grid item xs={12} className={"mt-4"}>
                        <b>Randevu Bilgileri</b>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <b>Randevu Tarihi:</b> {date.format("DD/MM/YYYY")}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <b>Randevu Saati:</b> {selectedScheduleText}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <b>Psikolog:</b> {doctorText}
                    </Grid>
                    <Grid item xs={12}>
                        <hr/>
                    </Grid>
                </Grid>
            </React.Fragment>
        </>
    )
}
export default Confirmation