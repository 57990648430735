import getPatientByID from "../../Helpers/GetPatientByID";
import {useEffect, useState} from "react";
import {query,collection, onSnapshot,updateDoc, doc, addDoc,deleteDoc} from 'firebase/firestore'
import {db} from "../../Firebase";
import Grid from "@mui/material/Grid";
import * as React from "react";
import dayjs from "dayjs";
import getDoctorByID from "../../Helpers/GetDoctorByID";
import {InputLabel, MenuItem, Select,FormControl} from "@mui/material";
import getSchecule from "../../Helpers/GetScheculeByID";
import getServices from "../../Helpers/GetServices";
import getAppointmentStates from "../../Helpers/GetAppointmentStatates";
import DeleteButtonWithDialog from "./DeleteButtonWithDialog";
import TextField from "@mui/material/TextField";
import ConfirmPaymentWithDialog from "./ConfirmPaymentWithDialog";
import setPriceByAppointmentID from "../../Helpers/SetPriceByAppointmentID";

function AppointmentCompanent({appointment}){

    const [patient,setPatient] = useState(null)
    const [states, setStates] = useState(null)
    const [doctor, setDoctor] = useState(null)
    const [schedule, setSchedule] = useState(null)

    const [price,setPrice] = useState(appointment.price)
    const [service, setService] = useState(appointment.serviceID)
    const [services, setServices] = useState(null)
    const [state, setState] = useState(appointment.appointmentStatesID)

    useEffect(()=>{
        getPatientByID(appointment.patientID,setPatient)
        getDoctorByID(appointment.doctorID,setDoctor)
        getSchecule(appointment.scheduleID,setSchedule)
        getServices(setServices)
        getAppointmentStates(setStates)

    },[])

    useEffect(()=>{
        const appointmentService = async () =>{
            await updateDoc(doc(db,'appointments', appointment.id),{
                serviceID: service
            })
        }
        appointmentService()
    },[service])

    useEffect(()=>{
        const appointmentState = async () =>{
            await updateDoc(doc(db,'appointments', appointment.id),{
                appointmentStatesID: state,
                blockTime:state=="ZgttzwgvXnQA9SHaakw0"?false:true
            })
        }
        appointmentState()
    },[state])

    return(
        <Grid container spacing={4} rowSpacing={2}>
            <Grid item md={3}>
                <a href={"/dashboard/patient/"+(patient?patient.id:"")}>
                    <b>Ad-Soyad:</b>  {patient?patient.name:''} {patient?patient.surname:''}
                </a>
            </Grid>

            <Grid item md={3}>
                <b>Tarih</b> {dayjs(appointment.date).format("DD/MM/YY")} {schedule?schedule.startTime:''}-{schedule?schedule.endTime:''}
            </Grid>

            <Grid item md={3}>
                <b>Doktor</b> {doctor?doctor.name:''} {doctor?doctor.surname:''}
            </Grid>

            <Grid item md={3}>
                <DeleteButtonWithDialog id={appointment.id}></DeleteButtonWithDialog>
            </Grid>

            <Grid item md={3}>
                <FormControl fullWidth>
                    <InputLabel id="randevuDurumu">Randevu Durumu</InputLabel>
                    <Select
                        labelId="randevuDurumu"
                        value={state}
                        label="Randevu Statüsü"
                        onChange={e => {
                            setState(e.target.value)
                        }}
                    >
                        {
                            states?states.map((sta,index)=>(
                                <MenuItem key={index} value={sta.id}>{sta.state}</MenuItem>
                            )):(<MenuItem value={0}>Onay Bekleniyor</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Grid>

            <Grid item md={3}>
                <FormControl fullWidth>
                    <InputLabel id="service">Hizmet</InputLabel>
                    <Select
                        labelId="service"
                        value={service}
                        label="Hizmetler"
                        onChange={e => {
                            setService(e.target.value)
                            services.map((serv,index)=> {
                                if (serv.id == e.target.value) {
                                    setPrice(serv.price)
                                    setPriceByAppointmentID(appointment.id,serv.price)
                                }
                            })

                        }}
                    >
                        {
                            services?services.map((serv,index)=>(
                                <MenuItem key={index} value={serv.id}>{serv.serviceName}</MenuItem>
                            )):(<MenuItem value={service}>Hizmet Seçilmedi</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Grid>

            <Grid item md={6}>
                <ConfirmPaymentWithDialog appointment={appointment} service={service} price={price} setPrice={setPrice} />
            </Grid>



            <Grid item md={12}>
                <hr/>
            </Grid>
        </Grid>
    )
}
export default AppointmentCompanent