import {useEffect, useState} from "react";
import dayjs from "dayjs";
import getAppointmentsUpcomingWithFilter from "../../../Helpers/GetAppointmentsUpcomingWithFilter";
import * as React from "react";
import Paper from "@mui/material/Paper";
import Title from "../Title";
import Grid from "@mui/material/Grid";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import AppointmentCompanent from "../AppointmentCompanent";

function PastAppointments(){

    const [appointments, setAppointments] = useState([])

    const[timeFilter,setTimeFilter] = useState(10)
    const handleChange = (event: SelectChangeEvent) => {
        setTimeFilter(event.target.value);
    };

    useEffect(()=>{
        var weekOfYear = require('dayjs/plugin/weekOfYear')
        dayjs.extend(weekOfYear)

        var startTimeLimit = 0
        var endTimeLimit = 0
        if(timeFilter == 10){
            startTimeLimit = dayjs().startOf("day").unix()
            endTimeLimit = dayjs().unix()
        }
        else if(timeFilter == 20){
            var target_week =  dayjs().week() - 1
            if(target_week<0){
                target_week = 0
            }
            startTimeLimit = dayjs().week(target_week).startOf("week").unix()
            endTimeLimit = dayjs().week(target_week).endOf("week").unix()
        }
        else if(timeFilter == 30){
            var target_month = dayjs().month() -1
            if(target_month<0){
                target_month = 0
            }
            startTimeLimit = dayjs().month(target_month).startOf("month").unix()
            endTimeLimit = dayjs().month(target_month).endOf("month").unix()
        }
        //getAppointmentsUpcoming(setAppointments)
        getAppointmentsUpcomingWithFilter(startTimeLimit,endTimeLimit,setAppointments)
    },[timeFilter])

    return (
        <React.Fragment>
            <div className="p-4">
                <Paper>
                    <div className="p-4 mb-4">
                        <Title>Geçmiş Randevular</Title>
                        <Grid container spacing={4} rowSpacing={2}>
                            <Grid item md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Zaman</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={timeFilter}
                                        label="Age"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>Bu Gün</MenuItem>
                                        <MenuItem value={20}>Geçen Hafta</MenuItem>
                                        <MenuItem value={30}>Geçen Ay</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {
                            appointments == ""?(<div className="p-6">Randevu Bulunmamaktadır!</div>):appointments.map((app)=>(
                                <div className="mt-6 p-4 hover:bg-gray-100">
                                    <AppointmentCompanent key={app.id} appointment={app}/>
                                </div>
                            ))

                        }
                    </div>
                </Paper>
            </div>


        </React.Fragment>
    )

}

export default PastAppointments