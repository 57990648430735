import Title from "../Title";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {Alert} from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import * as React from "react";
import {useEffect, useState} from "react";
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../../Firebase";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';


function PatientInfo({patient}){

    const [patientName, setPatientName] = useState("")
    const [patientSurName, setPatientSurName] = useState("")
    const [patientPhone, setPatientPhone] = useState("")
    const [patientEmail, setPatientEmail] = useState("")
    const [patientIdentity, setPatientIdentity] = useState("")
    const [patientFatherName, setPatientFatherName] = useState("")
    const [patientAddress, setPatientAddress] = useState("")
    const [patientBirthDate, setPatientBirthDate] = useState("")

    const [isPatientInfoChanged, setIsPatientInfoChanged] = useState(false)

    useEffect(()=>{
        if(patient){
            setPatientName(typeof patient.name!="undefined"?patient.name:"")
            setPatientSurName(typeof patient.surname!="undefined"?patient.surname:"")
            setPatientPhone(typeof patient.phoneNumber!="undefined"?patient.phoneNumber:"")
            setPatientEmail(typeof patient.eposta!="undefined"?patient.eposta:"")
            setPatientIdentity(typeof patient.identity!="undefined"?patient.identity:"")
            setPatientFatherName(typeof patient.fathername!="undefined"?patient.fathername:"")
            setPatientAddress(typeof patient.address!="undefined"?patient.address:"")
            setPatientBirthDate(typeof patient.birthDate!="undefined"?patient.birthDate:"")
        }
    },[patient])

    useEffect(()=>{
        if(patient){
            if(patient.name != patientName ||
                patient.surname != patientSurName ||
                patient.phoneNumber != patientPhone ||
                patient.eposta != patientEmail ||
                patient.identity != patientIdentity ||
                patient.fathername != patientFatherName ||
                patient.address != patientAddress|| 
                patient.birthDate != patientBirthDate){

                setIsPatientInfoChanged(true)
            }
            else{
                setIsPatientInfoChanged(false)
            }
        }
    },[patientName,patientSurName,patientPhone,patientAddress,patientIdentity,patientAddress,patientFatherName,patientEmail,patientBirthDate])

    const savePatientInfo = () =>{
        setIsPatientInfoChanged(false)
        const updatePatientInfo = async () =>{
            await updateDoc(doc(db,'patients', patient.id),{
                name: patientName,
                surname: patientSurName,
                phoneNumber: patientPhone,
                eposta: patientEmail,
                identity: patientIdentity,
                fathername: patientFatherName,
                address: patientAddress,
                birthDate:patientBirthDate
            })
        }
        updatePatientInfo()
    }

    return(
        <Paper>
            <div className="p-6">
                <Title>Danışan Bilgileri</Title>
                <Grid container spacing={4} rowSpacing={2}>
                    <Grid item md={3}>
                        <TextField fullWidth value={patientName} onChange={e=>setPatientName(e.target.value)} label="Ad" variant="outlined" />
                    </Grid>
                    <Grid item md={3}>
                        <TextField fullWidth value={patientSurName} onChange={e=>setPatientSurName(e.target.value)} label="Soyad" variant="outlined" />
                    </Grid>
                    <Grid item md={3}>
                        <TextField fullWidth value={patientPhone} onChange={e=>setPatientPhone(e.target.value)} label="Telefon" variant="outlined" />
                    </Grid>
                    <Grid item md={3}>
                        <TextField fullWidth value={patientEmail} onChange={e=>setPatientEmail(e.target.value)} label="E-Mail" variant="outlined" />
                    </Grid>
                    <Grid item md={3}>
                        <TextField fullWidth value={patientIdentity} onChange={e=>setPatientIdentity(e.target.value)} label="TC Kimlik" variant="outlined" />
                    </Grid>
                    <Grid item md={3}>
                        <TextField fullWidth value={patientFatherName} onChange={e=>setPatientFatherName(e.target.value)} label="Baba Adı" variant="outlined" />
                    </Grid>
                    
                    <Grid item md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} localeText="tr" adapterLocale={"tr"}>
                            
                                <DatePicker  label="Doğum Tarihi" 
                                 value={dayjs.unix(patientBirthDate)}
                                 onChange={(newValue) => setPatientBirthDate(dayjs(newValue).unix())}
                                />
                           
                        </LocalizationProvider>
                    </Grid>
                    <Grid item md={6}>
                        <TextField fullWidth value={patientAddress} onChange={e=>setPatientAddress(e.target.value)} label="Adres" variant="outlined" />
                    </Grid>
                    {
                        isPatientInfoChanged?(
                            <Grid item md={6} >
                                <Alert fullWidth severity="warning" action={
                                    <Button color="inherit" size="small" onClick={savePatientInfo}>
                                        Kaydet
                                    </Button>
                                }>
                                    Danışan bilgileri değişti kaydetmek istiyormusunuz?
                                </Alert>
                            </Grid>

                        ):(<></>)
                    }

                </Grid>
            </div>
        </Paper>
    )
}
export default PatientInfo