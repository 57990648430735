import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {deleteDoc, doc, updateDoc} from "firebase/firestore";
import {db} from "../../../Firebase";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {useEffect, useState} from "react";
import {Alert} from "@mui/material";

function Note({note}){
    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [isTextChanged, setIsTextChanged] = useState(false)
    const [noteText, setNoteText] = useState(note.text)
    useEffect(()=>{
        if(note.text!=noteText){
            setIsTextChanged(true)
        }else{
            setIsTextChanged(false)
        }
    },[noteText])

    const saveNoteText = () =>{
        setIsTextChanged(false)
        const updatePatientInfo = async () =>{
            await updateDoc(doc(db,'patientsNotes', note.id),{
                text:noteText,
                updatedAt:dayjs().unix()
            })
        }
        updatePatientInfo()
    }

    return(
        <div className="mt-4 hover:bg-gray-50 rounded">
            <Grid container spacing={4} rowSpacing={2}>
                <Grid item md={4}>
                    Notun Oluşturulma tarihi: {dayjs.unix(note.createdAt).format("DD/MM/YY HH:mm")}
                </Grid>
                <Grid item md={4}>
                    Notun Güncellenme Tarihi: {note.updatedAt?dayjs.unix(note.updatedAt).format("DD/MM/YY  HH:mm"):("Güncellenme Yok")}
                </Grid>
                <Grid item md={1}>

                </Grid>
                <Grid item md={3}>
                    <Button fullWidth variant="outlined" startIcon={<DeleteOutline />} onClick={handleClickOpen}>
                        Notu Sil
                    </Button>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Notu Silmeyi Onaylıyormusunuz?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Silme işlemi sonrası not geri getirilemez ve tamamen kaybolur.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Kapat</Button>
                            <Button onClick={()=>{
                                handleClose()
                                const deleteAppointments = async (todo) =>{
                                    await deleteDoc(doc(db,'patientsNotes',note.id))
                                }
                                deleteAppointments()

                            }
                            } autoFocus>
                                Kaydı Sil
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
                <Grid item  md={12}>
                    <TextField
                        fullWidth
                        id="filled-multiline-flexible"
                        label="Not"
                        value={noteText}
                        onChange={e=>setNoteText(e.target.value)}
                        multiline
                        maxRows={4}
                        variant="outlined"
                    />
                </Grid>
                {
                    isTextChanged?(
                        <Grid item md={8} >
                            <Alert fullWidth severity="warning" action={
                                <Button color="inherit" size="small" onClick={saveNoteText} >
                                    Kaydet
                                </Button>
                            }>
                                Not üzerinde yapılan değişiklikleri kaydetmek istiyormusunuz?
                            </Alert>
                        </Grid>

                    ):(<></>)
                }
                <Grid item md={12}>
                    <hr/>
                </Grid>
            </Grid>
        </div>
    )
}
export default Note