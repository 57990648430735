import Title from "../Title";
import * as React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import getPsychologists from "../../../Helpers/GetPsychologists";
import { TextField, Button } from "@mui/material";
import PsychologistItem from "./Components/PsychologistItem";
import getWorkLeaveList from "../../../Helpers/GetWorkLeaveList";
import WorkLeaveListItem from "./Components/WorkLeaveListItem";
import { query,collection ,addDoc,orderBy,doc, deleteDoc, onSnapshot, where, getDocs} from "firebase/firestore";
import { db } from "../../../Firebase";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function PsychologistManagement(){
    const [psychologist, setPsychologist] = useState([])
    useEffect(()=>{
        getPsychologists(setPsychologist)
    },[])

    const[workLeaveList, setWorkLeaveList] = useState([])
    useEffect(()=>{

        const q = query(
            collection(db, 'workLeaveList')
        )

        const unsubscribe = onSnapshot(q,(querySnapshot) => {
            querySnapshot.forEach((doc)=>{
                getWorkLeaveList(setWorkLeaveList)
            });
        })
        return () => unsubscribe

        
    },[])

    const [newPsychologName, setNewPsychologName] = useState('')
    const [newPsychologSurname, setNewPsychologSurname] = useState('')
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function createSchedule(newDoctorID){
        
        const q_week = query(
            collection(db,'scheduling'),
            orderBy("weekday")
        )
        const doc_week = await getDocs(q_week)
        doc_week.docs.map(async(day)=>{
            if(day.data().doctorId=="Hjck4uVgU65JP0Kf1pDJ"){
                const q_times = query(
                    collection(db,'scheduling/'+day.id+'/times'),
                    orderBy("order")
                )
                const doc_times = await getDocs(q_times)
                const week_day = await addDoc(collection(db,'scheduling'),{
                    weekday: day.data().weekday,
                    doctorId:newDoctorID
                })
                console.log("Gün",day.data().weekday)
                doc_times.docs.map(async (time)=>{
                    
                    await addDoc(collection(db,'scheduling/'+week_day.id+'/times'),{
                        order: time.data().order,
                        startTime: time.data().startTime,
                        endTime:time.data().endTime,
                        active:time.data().active
                    })
                })
            }
        })
    }
    

    return(
        <div>
            <React.Fragment>
                <div className="p-2">
                    <Paper>
                        <div className="p-4 mb-4">
                            <Title>Psikolog Yönetimi</Title>
                        </div>
                    </Paper>
                </div>
                
                <div className="p-2">
                    <Paper>
                        <div className="p-4 mb-4">
                            <Title>Yeni Psikolog Ekleme</Title>
                            <Grid container spacing={4} rowSpacing={2}>
                                <Grid item md={4}>
                                    <TextField fullWidth id="outlined-basic" label="Ad" variant="outlined" 
                                    onChange={e=>setNewPsychologName(e.target.value)}/>
                                </Grid>
                                <Grid item md={4}>
                                    <TextField fullWidth id="outlined-basic" label="Soyad" variant="outlined" 
                                    onChange={e=>setNewPsychologSurname(e.target.value)}/>
                                </Grid>
                                <Grid item md={4}>
                                    <div className="mt-2">
                                    <Button variant="outlined" color="primary" 
                                    onClick={handleClickOpen}>
                                      Yeni Psikolog Ekle
                                    </Button>
                                    <Dialog
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                            {"Yeni psikolog eklemeyi onaylıyormusnuz?"}
                                            </DialogTitle>
                                            <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                
                                            </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                            <Button onClick={handleClose}>Onaylamıyorum</Button>
                                            <Button onClick={async ()=>{
                                                handleClose()
                                                const doctor = await addDoc(collection(db,'doctors'),{
                                                    name: newPsychologName,
                                                    surname:newPsychologSurname,
                                                    active:false
                                                })
                                                console.log("Yeni Doktor",doctor.id)
                                                createSchedule(doctor.id)

                                            }} autoFocus>
                                                Onaylıyorum
                                            </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </div>

                <div className="p-2">
                    <Paper>
                        <div className="p-4 mb-4">
                            <Title>Mevcut Psikologlar</Title>
                            <Grid container spacing={4} rowSpacing={2}>
                                {
                                    psychologist.map((psy)=>(
                                            <PsychologistItem psychologist={psy} />
                                    ))
                                }
                            </Grid>
                        </div>
                    </Paper>
                </div>

                <div className="p-2">
                    <Paper>
                        <div className="p-4 mb-4">
                            <Title>Mevcut İzinler</Title>
                            <Grid container spacing={4} rowSpacing={2}>
                                {
                                    workLeaveList.map((leave)=>(
                                          <WorkLeaveListItem leave={leave} psychologistList={psychologist} />
                                    ))
                                }
                            </Grid>
                        </div>
                    </Paper>
                </div>

            </React.Fragment>
        </div>
    )
}
export default PsychologistManagement