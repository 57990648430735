import {collection, getDocs, query} from "firebase/firestore";
import {db} from "../Firebase";

async function getAppointmentStates( setFunction) {
    const q = query(
        collection(db, 'appointmentStates')
    )
    const docs = await getDocs(q)

    var list = []
    docs.docs.map(async function (doc) {
        list.push({...doc.data(),id:doc.id})
        setFunction(list)
    })
}
export default getAppointmentStates