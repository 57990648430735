import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MontlyTotalIncome from "./MontlyTotalIncome";
import {TablePagination} from "@mui/material";
import {collection, getDocs, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import AppointmentCompanent from "./AppointmentCompanent";
import getAppointmentsUpcoming from "../../Helpers/GetAppointmentsUpcoming";

export default function Orders() {

  var startOfThisMount = dayjs().startOf('month').unix()
  const [appointments, setAppointments] = useState([])

  useEffect(()=>{
    const listen_appointments = query(collection(db,'appointments'),where("timestamp", ">=", startOfThisMount),orderBy("timestamp"))
    const unsubscribe = onSnapshot(listen_appointments,(querySnapshot) => {
      getAppointmentsUpcoming(setAppointments)
    })
    return () => unsubscribe
  },[])


  return (
    <React.Fragment>

      <Title>Bu Gün Gerçekleşecek Randevular</Title>
        {
          appointments.map((app)=>(
              <div className="mt-6 hover:bg-gray-100">
                <AppointmentCompanent key={app.id} appointment={app}/>
              </div>
          ))
        }


    </React.Fragment>


  );
}
