// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBhAQMO_5AcRVQEtCbtukp9eiFcOegchGU",
    authDomain: "appointment-system-7fe73.firebaseapp.com",
    projectId: "appointment-system-7fe73",
    storageBucket: "appointment-system-7fe73.appspot.com",
    messagingSenderId: "422454853085",
    appId: "1:422454853085:web:76c1be2c4768b9a47eca34"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)