import {Alert, AlertTitle} from "@mui/material";
import * as React from "react";

function AlertBox({type,body,visible}){
    if(visible){
        return(
            <Alert severity={type}>{body}</Alert>
        )
    }
}
export default AlertBox